// .teacher_tree{
//     flex-grow: 1;
//     & .MuiTreeView-root .MuiTreeItem-root::before {
//       content: '""',
//       position: 'absolute',
//       top: '50%',
//       left: 0,
//       width: 1,
//       height: '50%',
//       backgroundColor: theme.palette.divider,
//       zIndex: -1,
//     }
//     & .MuiTreeView-root .MuiTreeItem-root .MuiTreeItem-label::before {
//       content: '""',
//       position: 'absolute',
//       top: 0,
//       left: -8,
//       width: 8,
//       height: '100%',
//       borderTop: `1px solid ${theme.palette.divider}`,
//       zIndex: -1,
//     };
//     & .MuiTreeView-root .MuiTreeItem-root:last-child .MuiTreeItem-label::before {
//       height: '50%',
//     };
// }

.animated-text:hover {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  animation: cycle-animation 5s infinite linear;
}
@keyframes cycle-animation {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animated-text {
  animation-play-state: paused;
}

.admin_menu_txt_selected {
  &.MuiTypography-root {
    font-weight: 600;
    font-family: 'Inter';
    font-style: normal;
    background-color: unset;
    color: black !important;
    font-size: 14px;
    //align-self: center;
  }
}
.admin_menu_txt {
  &.MuiTypography-root {
    font-weight: 600;
    font-family: 'Inter';
    font-style: normal;
    color: black;
    font-size: 14px;
    //align-self: center;
  }
}

.menu_txt {
  &.MuiTypography-root {
    font-weight: 700;
    font-family: 'Inter';
    font-style: normal;
    color: #666666;
  }
}

.white_bg_btn {
  &.MuiButton-root {
    color: #242424;
    background-color: #fff;
  }
}

.follower_btn {
  &.MuiButton-root {
    border-radius: 30px;
    border: 1px solid #20a58a;
    color: #434e58;
    background-color: #fff;
  }
}

.gray_bg_btn {
  &.MuiButton-root {
    color: #000;
    background-color: #d9d9d9;
    min-width: 112px;
    box-shadow: none;

    @media screen and (max-width: 600px) {
      border-radius: 44px;
    }
  }

  &.MuiButton-root:hover {
    color: #000;
    background-color: #d9d9d9;
    min-width: 112px;

    @media screen and (max-width: 600px) {
      border-radius: 44px;
    }
  }
}

.dark_gray_bg_btn {
  &.MuiButton-root {
    color: #000;
    background-color: #b0adad;
    min-width: 112px;
    box-shadow: none;
  }

  &.MuiButton-root:hover {
    color: #000;
    background-color: #b0adad;
    min-width: 112px;
  }
}

.custom_green {
  span {
    color: #20a58a !important;
  }
}
.light_green_btn {
  &.MuiButton-root {
    background-color: #66cb63;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  }
  &.MuiButton-root:hover {
    background-color: #66cb63;
  }
}
.green_bg_btn_bold {
  &.MuiButton-root {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  }
  &.MuiButton-root:hover {
  }
}
.MuiAutocomplete-input {
  text-transform: capitalize !important;
}
.green_bg_btn {
  &.MuiButton-root {
    color: #20a58a !important;
    background-color: #ddfaec;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
    text-transform: none !important;
    @media screen and (max-width: 600px) {
      border-radius: 44px;
    }
  }

  &.MuiButton-root:hover {
    color: a #20a58a;
    background-color: #ddfaec;
    box-shadow: none;

    @media screen and (max-width: 600px) {
      border-radius: 44px;
    }
  }
}
.assign-dialog {
  & .Mui-checked {
    color: #c73e9d !important;
  }
  & .css-1l1wl9s-MuiPaper-root-MuiDialog-paper {
    max-width: 650px;
  }
}
.filter-dialog {
  & .css-1y1r89k-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #c73e9d;
  }
  & .css-1l1wl9s-MuiPaper-root-MuiDialog-paper {
    max-width: 650px;
  }
}
.red_bg_btn {
  &.MuiButton-root {
    color: #fff !important;
    background-color: #f41f52;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
    text-transform: none !important;
    @media screen and (max-width: 600px) {
      border-radius: 44px;
    }
  }

  &.MuiButton-root:hover {
    color: #efbcc8;
    background-color: #f41f52;
    box-shadow: none;

    @media screen and (max-width: 600px) {
      border-radius: 44px;
    }
  }
}

.primary_bg_btn_outlined {
  &.MuiButton-root {
    border: 2px solid #c73e9d !important;
    color: #c73e9d;
    box-shadow: none;
    font-weight: 500;

    @media screen and (max-width: 600px) {
      color: #c73e9d !important;
      border: 2px solid #c73e9d !important;
    }
  }

  &.MuiButton-root:hover {
    color: #c73e9d;
    background-color: #c73e9d;

    @media screen and (max-width: 600px) {
      border-radius: 44px;
    }
  }
}
.primary_bg_btn {
  &.MuiButton-root {
    color: #fff;
    background-color: #c73e9d;
    box-shadow: none;
    font-weight: 500;

    @media screen and (max-width: 600px) {
      border-radius: 44px;
    }
  }

  &.MuiButton-root:hover {
    color: #fff;
    background-color: #c73e9d;

    @media screen and (max-width: 600px) {
      border-radius: 44px;
    }
  }
}

.filter_button {
  &.MuiButton-root {
    color: #20a58a;
    background-color: #e0efec;
    box-shadow: none;
  }

  &.MuiButton-root:hover {
    color: #20a58a;
    background-color: #e0efec;
  }
}

.dialog_red_button {
  &.MuiButton-root {
    min-width: 120px;
    color: #ffffff;
    background-color: #f41f52;
    box-shadow: none;
  }

  &.MuiButton-root:hover {
    color: #ffffff;
    background-color: #f41f52;
  }
}

.dialog_grey_button {
  &.MuiButton-root {
    min-width: 120px;
    color: #131515;
    background-color: #d9d9d9;
    box-shadow: none;
  }

  &.MuiButton-root:hover {
    color: #131515;
    background-color: #d9d9d9;
  }
}

.abbreviation_txt {
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 18px;
    font-family: 'Inter';
    font-style: normal;
    color: #434e58;
  }
}

.profile_name_txt {
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 18px;
    font-family: 'Inter';
    font-style: normal;
    color: #131515;
  }
}

.title_text_model {
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 16px;
    font-family: 'Inter';
    font-style: normal;
    color: #000;
  }
}

.subtitle_text_model {
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    color: #84818a;
  }
}

.title_text {
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 16px;
    font-family: 'Inter';
    font-style: normal;
  }
}

.subtitle_text {
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    color: #84818a;
  }
}

.entries-page-txt {
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    color: #333333;
  }
}

.MuiTableContainer-root {
  table {
    thead {
      background: #e9f6f3;
      z-index: 9;
      box-shadow: 0 5px 5px -6px rgba(0, 0, 0, 0.15);
      height: 50px;
      text-align: left;
    }
  }
}

.assign-content .MuiTableContainer-root {
  table {
    thead {
      background: none;
      z-index: 9;
      box-shadow: none;
      height: 50px;
      text-align: left;
    }
  }
}

.MuiTypography-root {
  &.table_title {
    font-size: 20px;
    font-weight: 500;
    color: #131515;
  }
}

.MuiPaper-rounded {
  &.radiusLess_card {
    border-radius: 6px;
    padding: 18px;
  }
}

// Common table
.custom-table {
  border: 1px solid #e9ebed;
  border-radius: 6px;
}

.border-less-table {
  border: none;
}
.input-questions:focus {
  outline: 2px solid #20a58a !important;
}
.accordion-wrapper > div {
  box-shadow: 0px 1px 3px 0px #00000014 !important;
  width: 100% !important;
}

//SignUP SCSS

.Main_div {
  background: #e5f6f4;
  height: 100dvh;

  @media only screen and (max-width: 639px) {
    height: auto;
  }

  .inner_div {
    padding: 22px;
    height: 50px;

    > img {
      object-fit: contain;
      margin: 30px 60px;
      width: 110px;
      height: 48.11px;
    }
  }

  .fromMain {
    display: flex;
    padding: 60px 0px;

    @media only screen and (max-width: 639px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left_hand_side {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 50%;

      @media only screen and (max-width: 639px) {
        width: 90%;
      }

      .image_right {
        display: flex;
        justify-content: end;
        height: 80%;

        margin-left: 100px;

        @media only screen and (max-width: 639px) {
          height: 300px;
          margin-top: 10px;
        }

        @media only screen and (max-width: 943px) {
          margin-left: 0px;
        }

        @media only screen and (max-width: 943px) {
          margin-left: 0px;
          height: 250px;
        }

        > img {
          height: 100%;
          width: 100%;
          object-fit: contain;

          @media only screen and (max-width: 639px) {
          }
        }
      }
    }

    .right_hand_side {
      width: 50%;

      @media only screen and (max-width: 639px) {
        width: 90%;
        display: flex;
        justify-content: center;
      }

      .from_section {
        height: 70vh;
        width: 53%;
        padding: 5px;

        background: #ffffff;
        border-radius: 8px;

        @media only screen and (max-width: 1112px) {
          width: 60%;
        }

        @media only screen and (max-width: 929px) {
          width: 70%;
        }

        @media only screen and (max-width: 810px) {
          width: 80%;
        }

        @media only screen and (max-width: 639px) {
          width: 66%;
        }

        @media only screen and (max-width: 441px) {
          width: 80%;
        }

        @media only screen and (max-width: 331px) {
          width: 100%;
        }

        > p {
          text-align: center;
          font-size: 24px;
          font-weight: 600;
          line-height: 34px;

          @media only screen and (max-width: 703px) {
            font-size: 20px;
          }
        }

        .main_contact_form {
          height: 70%;
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          align-items: center;

          .inner_section_from {
            .end_section {
              width: 74.5%;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: space-between;

              > p {
                color: #110c1d;
                font-family: 'Plus Jakarta Sans';
                font-style: normal;
                font-weight: 600;
                padding-left: 4px;

                font-size: 20px;

                @media only screen and (max-width: 703px) {
                  font-size: 17px;
                }
              }

              .icon_end {
                color: #979c9e;
                padding: 0px 6px;
                font-size: 23px;
              }
            }

            border: 0.2px solid#979C9E;
            width: 90%;
            display: flex;
            justify-content: space-between;
            border-radius: 10px 5px 5px 10px;

            height: 60px;

            .section_contacts {
              width: 57%;
              border-radius: 10px 1px 1px 10px;
              height: 100%;
              width: 25%;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1;
              background: linear-gradient(286.17deg, #246bfd 0%, #5089fd 100%);

              .icon_main {
                height: 65%;
                background: #0b5dff;
                width: 50%;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon_from {
                  color: #ffffff;
                  font-size: 21px;
                }
              }
            }

            .section_contacts1 {
              width: 57%;
              border-radius: 10px 1px 1px 10px;
              height: 100%;
              width: 25%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: linear-gradient(286.17deg, #fb9400 0%, #ffab38 100%);

              .icon_main {
                height: 65%;
                background: #fb9400;
                width: 50%;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon_from {
                  color: #ffffff;
                  font-size: 21px;
                }
              }
            }

            .section_contacts2 {
              width: 57%;
              border-radius: 10px 1px 1px 10px;
              height: 100%;
              width: 25%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: linear-gradient(
                286.17deg,
                #20a58a 100% 0%,
                #71e3bb 100%,
                #71e3bb 100%
              );

              .icon_main {
                height: 65%;
                background: #71e3bb;
                width: 50%;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon_from {
                  color: #ffffff;
                  font-size: 21px;
                }
              }
            }

            .section_contacts3 {
              width: 57%;
              border-radius: 10px 1px 1px 10px;
              height: 100%;
              width: 25%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: linear-gradient(
                286.17deg,
                #c73e9d 100% 0%,
                #c73e9d 100%
              );

              .icon_main {
                height: 65%;
                background: #9b4581;
                width: 50%;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon_from {
                  color: #ffffff;
                  font-size: 21px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ellipse-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.create_from_scratch_view {
  padding: 24px;
  border-radius: 8px;
  background-color: #f6e3f0;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: 176px;

  & .create_from_scratch_button {
    background-color: white;
    width: 100%;
    color: #484848;
    font-size: 16px;
    font-weight: 500;
  }
}
.arrow-image {
  transition: transform 0.35s;
}

.arrow-expanded {
  transform: rotate(180deg);
}

.create_quiz_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  height: 685px;
  background-color: white;
  box-shadow: 24px;
  border-radius: 8px;
  overflow: auto !important;

  & .modal_header {
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px;
    border-bottom-color: #ccc;
    padding: 16px;
  }
}

.cancel_button {
  &.MuiButton-root {
    background-color: #d9d9d9;
    color: #000000;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
  }

  &.MuiButton-root:hover {
    color: #000000;
    background-color: #bbb;
  }
}

.header_txt {
  &.MuiTypography-root {
    font-family: Inter;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    color: #292929;
  }
}

.header_title {
  &.MuiTypography-root {
    font-family: Inter;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #484848;
  }
}

.header_content {
  &.MuiTypography-root {
    font-family: Inter;
    font-style: normal;
    font-size: 13px;
    font-weight: 400;
    color: #84818a;
    text-align: center;
    line-height: 24px;
  }
}

// .Main_div {
//     background: #e5f6f4;
//     height: 100dvh;

//     .inner_div {
//         height: 50px;

//         >img {
//             object-fit: contain;
//             margin: 30px 60px;
//             width: 110px;
//             height: 48.11px;
//         }
//     }

//     .fromMain {
//         display: flex;
//         padding: 60px 0px;

//         .left_hand_side {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             width: 50%;

//             .image_right {
//                 display: flex;
//                 justify-content: end;
//                 height: 80%;
//                 margin-left: 100px;

//                 >img {}
//             }
//         }

//         .right_hand_side {
//             width: 50%;

//             .from_section {
//                 background-color: #FFFFFF;
//                 height: 70vh;
//                 width: 53%;

//                 padding: 5px;

//                 background: --color-white;
//                 border-radius: 8px;

//                 >p {
//                     text-align: center;
//                     font-size: 24px;

//                     font-weight: 600;
//                     line-height: 34px;
//                 }

//                 .main_contact_form {
//                     height: 70%;

//                     display: flex;
//                     justify-content: space-around;
//                     flex-direction: column;
//                     align-items: center;

//                     .inner_section_from {

//                         .end_section {
//                             width: 74.5%;
//                             cursor: pointer;
//                             display: flex;
//                             align-items: center;
//                             justify-content: space-between;

//                             >p {

//                                 color: #110C1D;
//                                 font-family: 'Plus Jakarta Sans';
//                                 font-style: normal;
//                                 font-weight: 600;
//                                 padding-left: 4px;

//                                 font-size: 20px;
//                             }

//                             .icon_end {
//                                 width: 24px;
//                                 height: 24px;
//                                 color: #979C9E;
//                                 margin-right: 12px;
//                                 padding: 0px 6px;
//                                 font-size: 32px;
//                                 background-color: #DCDCDC;
//                                 border-radius: 32px;
//                             }
//                         }

//                         border: 0.2px solid lightgray;
//                         width: 90%;
//                         display: flex;
//                         justify-content: space-between;
//                         border-radius: 10px 5px 5px 10px;

//                         height: 60px;

//                         .section_contacts {
//                             width: 57%;
//                             border-radius: 10px 1px 1px 10px;
//                             height: 100%;
//                             width: 25%;
//                             display: flex;
//                             align-items: center;
//                             justify-content: center;
//                             z-index: 1;
//                             background: linear-gradient(286.17deg, #246BFD 0%, #5089FD 100%);

//                             .icon_main {
//                                 height: 65%;
//                                 background: #0b5dff;
//                                 width: 50%;
//                                 border-radius: 50%;
//                                 display: flex;
//                                 align-items: center;
//                                 justify-content: center;

//                                 .icon_from {
//                                     color: --color-white;
//                                     font-size: 21px;
//                                 }
//                             }
//                         }

//                         .section_contacts1 {
//                             width: 57%;
//                             border-radius: 10px 1px 1px 10px;
//                             height: 100%;
//                             width: 25%;
//                             display: flex;
//                             align-items: center;
//                             justify-content: center;
//                             background: linear-gradient(286.17deg, #FB9400 0%, #FFAB38 100%);

//                             .icon_main {
//                                 height: 65%;
//                                 background: #FB9400;
//                                 width: 50%;
//                                 border-radius: 50%;
//                                 display: flex;
//                                 align-items: center;
//                                 justify-content: center;

//                                 .icon_from {
//                                     color: --color-white;
//                                     font-size: 21px;
//                                 }
//                             }
//                         }

//                         .section_contacts2 {
//                             width: 57%;
//                             border-radius: 10px 1px 1px 10px;
//                             height: 100%;
//                             width: 25%;
//                             display: flex;
//                             align-items: center;
//                             justify-content: center;
//                             background: linear-gradient(286.17deg, #20A58A 100% 0%, #71E3BB 100%, #71E3BB 100%);

//                             .icon_main {
//                                 height: 65%;
//                                 background: #71E3BB;
//                                 width: 50%;
//                                 border-radius: 50%;
//                                 display: flex;
//                                 align-items: center;
//                                 justify-content: center;

//                                 .icon_from {
//                                     color: --color-white;
//                                     font-size: 21px;
//                                 }
//                             }
//                         }

//                         .section_contacts3 {
//                             width: 57%;
//                             border-radius: 10px 1px 1px 10px;
//                             height: 100%;
//                             width: 25%;
//                             display: flex;
//                             align-items: center;
//                             justify-content: center;
//                             background: linear-gradient(286.17deg, #C73E9D 100% 0%, #C73E9D 100%);

//                             .icon_main {
//                                 height: 65%;
//                                 background: #9b4581;
//                                 width: 50%;
//                                 border-radius: 50%;
//                                 display: flex;
//                                 align-items: center;
//                                 justify-content: center;

//                                 .icon_from {
//                                     color: --color-white;
//                                     font-size: 21px;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

.teacher_tabs_container {
  & .MuiTabs-flexContainer {
    border-bottom: 2px solid #dcdcdc;

    @media screen and (max-width: 600px) {
      display: flex;
      justify-content: space-evenly;
    }
  }
}

//teacher
.main_card {
  .inner_div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    background: #e9f6f3;

    .profile_teacher {
      display: flex;
      margin-top: 120px;

      @media screen and (max-width: 630px) {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        align-items: center;
      }

      .profile-section {
        width: 50%;
        display: flex;
        color: #131515;
        font-size: 18px;
        font-style: medium;
        font-family: inherit;
        padding: 0px 50px;

        @media screen and (max-width: 1088px) {
          padding: 0px 30px;
        }

        @media screen and (max-width: 799px) {
          padding: 0px 20px;
        }

        @media screen and (max-width: 630px) {
          width: 90%;
          justify-content: start;
          padding: 0px 20px;
        }

        > p {
          bottom: 0px;
          padding-top: 70px;
          padding-left: 10px;
          font-size: 18px;
          font-family: inherit;

          @media screen and (max-width: 765px) {
            font-size: 16px;
          }

          @media screen and (max-width: 685px) {
            padding-top: 40px;
          }

          @media screen and (max-width: 630px) {
            padding-top: 35px;
            font-size: 16px;
          }

          // font-size: 18px;
        }
      }

      .profile-section-rights_side {
        width: 50%;
        display: flex;
        height: 110px;
        margin-top: 15px;
        flex-direction: column;
        justify-content: space-around;

        @media screen and (max-width: 630px) {
          margin-top: 4px;
          height: 80px;
          width: 90%;
        }

        .rights_side {
          display: flex;
          display: flex;
          justify-content: end;
          padding: 0px 40px;

          @media screen and (max-width: 712px) {
            padding: 0px 20px;
            display: flex;
            justify-content: space-between;
          }

          @media screen and (max-width: 630px) {
            padding: 0px 20px;
            justify-content: end;
          }

          .rights_sidebtn {
            background-color: #ffffff;
            border-radius: 6px;
            padding: 8px;
            cursor: pointer;
            margin-left: 8px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            border: 1px solid #ffffff !important;

            .btn_span {
              @media screen and (max-width: 712px) {
                font-size: 12px;
              }
            }

            @media screen and (max-width: 712px) {
              // width: 45% !important;
            }
          }
        }

        .rights_side2 {
          display: flex;
          display: flex;
          justify-content: end;

          @media screen and (max-width: 630px) {
            padding: 0px 20px;
            justify-content: end;
          }
        }
      }
    }
  }

  .teacher_profie_taps {
    margin-top: 70px;

    @media screen and (max-width: 632px) {
      margin-top: 130px;
    }
  }
}

.avatar_profile {
  width: 126.82px !important;
  height: 126.82px !important;
  padding: 3px !important;
  border: 1px solid #20a58a !important;

  @media screen and (max-width: 685px) {
    width: 100px !important;
    height: 100px !important;
  }

  @media screen and (max-width: 630px) {
    width: 85px !important;
    height: 85px !important;
  }
}

// datatable
.datatable_card {
  &.MuiPaper-rounded {
    border-radius: 6px;
    padding: 18px;
  }
}

// @media screen and (max-width:542px) {

//     .Studentsbtn {
//         padding: 0px 3px !important;
//         font-size: 12px !important;
//     }

// }

// @media screen and (max-width:542px) {

//     .Studentsbtns {
//         font-size: 12px !important;
//     }
// }

//lesson
// .student-page {
//     padding: 3px;

//     .search_input_student {
//         padding: 10px 0px;

//         display: flex;

//         .search_input_ {
//             border: 1px solid #DCDCDC !important;
//             width: 20.5% !important;
//             border-radius: 6px !important;
//             height: 35px !important;

//         }

//         .filter {
//             background-color: #E0EFEC;
//             height: 35px;
//             border-radius: 8px;
//             margin-left: 14px;
//             border-style: dotted;
//             border-color: #E0EFEC;
//             width: 9%;
//             display: flex;
//             border: 1px dotted #20A58A;
//             justify-content: center;
//             align-items: center;
//             font-size: 14px;
//             color: #20A58A;
//             font-weight: 500;
//         }
//     }

//     .main_lessons {

//         display: flex;
//         margin-top: 10px;

//         margin: 20px 0px 0px 50px;
//         flex-wrap: wrap;

//         @media screen and (max-width:750px) {
//             justify-content: center;
//             margin: 20px 0px 0px 0px;
//         }

//         .main_card_lessons {
//             width: 21%;
//             margin: 20px;
//             border-radius: 8px;

//             height:
//                 340px;

//             @media screen and (max-width:1100px) {
//                 width: 25%;
//             }

//             @media screen and (max-width:750px) {
//                 width: 35%;
//             }

//             @media screen and (max-width:458px) {
//                 width: 75%;
//                 height:
//                     290px;
//             }

//             @media screen and (max-width:359px) {
//                 width: 85%;
//             }

//             >img {
//                 height: 100%;
//                 object-fit: fill;
//                 width: 100%;
//             }
//         }
//     }
// }

//student
// .student-page {
//     padding: 3px;

//     .search_input_student {
//         padding: 10px 0px;
//         display: flex;

//         .search_input_ {
//             border: 1px solid #DCDCDC !important;
//             width: 20.5% !important;
//             border-radius: 6px !important;
//             height: 35px !important;

//             @media screen and (max-width:900px) {
//                 width: 28.5% !important;

//             }

//             @media screen and (max-width:700px) {
//                 width: 38% !important;
//             }

//             @media screen and (max-width:558px) {
//                 width: 43% !important;
//             }

//             @media screen and (max-width:434px) {
//                 width: 63% !important;
//             }
//         }

//         .filter {
//             background-color: #E0EFEC;
//             height: 35px;
//             border-radius: 8px;
//             margin-left: 14px;
//             border-style: dotted;
//             border-color: #E0EFEC;
//             width: 9%;
//             display: flex;
//             border: 1px dotted #20A58A;
//             justify-content: center;
//             align-items: center;
//             font-size: 14px;
//             color: #20A58A;
//             font-weight: 500;

//             @media screen and (max-width:790px) {
//                 width: 12%;
//             }

//             @media screen and (max-width:558px) {
//                 width: 15%;
//             }

//             @media screen and (max-width:434px) {
//                 width: 18% !important;
//             }
//         }
//     }

//     .main_student {
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: space-between;

//         @media screen and (max-width:550px) {
//             justify-content: space-around;
//         }

//         .main_card {
//             width: 22.5%;
//             margin-top: 10px;

//             border-radius: 8px;
//             height: 250px;
//             background-color: #F9F9F9;

//             @media screen and (max-width:772px) {
//                 width: 29%;

//             }

//             @media screen and (max-width:635px) {
//                 width: 32%;

//             }

//             @media screen and (max-width:535px) {
//                 width: 38%;

//             }

//             @media screen and (max-width:468px) {
//                 width: 57%;

//             }

//             @media screen and (max-width:392px) {
//                 width: 67%;

//             }

//             >h3 {
//                 text-align: center;
//                 font-size: 16px;
//                 font-family: inherit;
//                 font-weight: 500;
//                 color: #484848;
//             }

//             >p {
//                 color: #84818A;
//                 font-size: 14px;
//                 text-align: center;
//                 font-family: inherit;
//                 font-style: Regular;
//             }

//             .img_student_profile {
//                 width: 100%;
//                 display: flex;
//                 justify-content: center;

//             }
//         }
//     }
// }

//upcoming
// .Upcoming_main {
//     .main_inner_upcoming {
//         display: flex;
//         justify-content: space-between;

//         @media screen and (max-width:694px) {
//             display: flex;
//             justify-content: center;
//         }

//         flex-wrap: wrap;

//         .box_upcoming {
//             border: dotted 2px #20A58A;
//             width: 48%;
//             margin-top: 10px;

//             padding: 6px;
//             border-radius: 7.22px;

//             @media screen and (max-width:756px) {
//                 width: 49%;
//             }

//             @media screen and (max-width:694px) {
//                 width: 70%;
//             }

//             @media screen and (max-width:499px) {
//                 width: 95%;
//             }

//             >p {
//                 font-weight: 500;
//                 font-size: 14px;
//                 color: #979C9E;

//                 @media screen and (max-width:756px) {
//                     font-size: 13px;

//                 }
//             }

//             .join_days {
//                 padding: 3px;

//                 display: flex;
//                 justify-content: space-between;
//                 align-items: center;
//                 height: 20px;
//                 margin-top: 7px;

//                 font-weight: 500;
//                 font-size: 16px;
//                 font: inherit;

//                 >p {
//                     color: #110C1D;

//                 }

//                 .Joinlive {
//                     margin-top: 7px;
//                     background-color: #C73E9D;
//                     height: 35px;
//                     border-radius: 8px;
//                     border: 2px solid #C73E9D;
//                     margin-left: 14px;
//                     width: 18%;
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;
//                     font-size: 14px;
//                     cursor: pointer;
//                     color: #FEFEFE;
//                     font-weight: 500;

//                     @media screen and (max-width:569px) {
//                         width: 21%;
//                     }

//                     @media screen and (max-width:499px) {
//                         width: 25%;
//                     }

//                     @media screen and (max-width:429px) {
//                         width: 25%;
//                         font-size: 12px;

//                     }

//                     @media screen and (max-width:397px) {
//                         width: 27%;
//                         font-size: 11px;

//                     }
//                 }

//                 .Joinlive1 {
//                     margin-top: 7px;
//                     background-color: #B0ADAD;
//                     height: 35px;
//                     border-radius: 8px;
//                     border: 2px solid#B0ADAD;
//                     margin-left: 14px;
//                     width: 18%;
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;
//                     font-size: 12px;
//                     cursor: pointer;
//                     color: #282837;
//                     font-weight: 600;

//                     @media screen and (max-width:336px) {
//                         width: 21%;
//                     }
//                 }
//             }

//         }
//     }
// }

//kids

// .student-page {
//     padding: 3px;

//     .search_input_student {
//         padding: 10px 0px;
//         display: flex;

//         .search_input_ {
//             border: 1px solid #DCDCDC !important;
//             width: 20.5% !important;
//             border-radius: 6px !important;
//             height: 35px !important;

//             @media screen and (max-width:900px) {
//                 width: 28.5% !important;

//             }

//             @media screen and (max-width:700px) {
//                 width: 38% !important;
//             }

//             @media screen and (max-width:558px) {
//                 width: 43% !important;
//             }

//             @media screen and (max-width:434px) {
//                 width: 63% !important;
//             }
//         }

//         .filter {
//             background-color: #E0EFEC;
//             height: 35px;
//             border-radius: 8px;
//             margin-left: 14px;
//             border-style: dotted;
//             border-color: #E0EFEC;
//             width: 9%;
//             display: flex;
//             border: 1px dotted #20A58A;
//             justify-content: center;
//             align-items: center;
//             font-size: 14px;
//             color: #20A58A;
//             font-weight: 500;

//             @media screen and (max-width:790px) {
//                 width: 12%;
//             }

//             @media screen and (max-width:558px) {
//                 width: 15%;
//             }

//             @media screen and (max-width:434px) {
//                 width: 18% !important;
//             }
//         }
//     }

//     .main_student {
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: space-between;

//         @media screen and (max-width:550px) {
//             justify-content: space-around;
//         }

//         .main_card {
//             width: 22.5%;
//             margin-top: 10px;

//             border-radius: 8px;
//             height: 250px;
//             background-color: #F9F9F9;

//             @media screen and (max-width:772px) {
//                 width: 29%;

//             }

//             @media screen and (max-width:635px) {
//                 width: 32%;

//             }

//             @media screen and (max-width:535px) {
//                 width: 38%;

//             }

//             @media screen and (max-width:468px) {
//                 width: 57%;

//             }

//             @media screen and (max-width:392px) {
//                 width: 67%;

//             }

//             >h3 {
//                 text-align: center;
//                 font-size: 16px;
//                 font-family: inherit;
//                 font-weight: 500;
//                 color: #484848;
//             }

//             >p {
//                 color: #84818A;
//                 font-size: 14px;
//                 text-align: center;
//                 font-family: inherit;
//                 font-style: Regular;
//             }

//             .img_student_profile {
//                 width: 100%;
//                 display: flex;
//                 justify-content: center;

//             }
//         }
//     }
// }

//schedule
// .Upcoming_main1 {
//     .main_inner_upcoming1 {
//         display: flex;
//         justify-content: space-between;

//         @media screen and (max-width:694px) {
//             display: flex;
//             justify-content: center;
//         }

//         flex-wrap: wrap;

//         .box_upcoming1 {
//             border: dotted 2px #20A58A;
//             width: 48%;
//             background: #E9F6F3;
//             margin-top: 10px;

//             padding: 6px;
//             border-radius: 7.22px;

//             @media screen and (max-width:756px) {
//                 width: 49%;
//             }

//             @media screen and (max-width:694px) {
//                 width: 70%;
//             }

//             @media screen and (max-width:499px) {
//                 width: 95%;
//             }

//             .join_inner {
//                 display: flex;
//                 justify-content: space-around;
//                 align-items: center;

//                 >p {
//                     font-size: 16px;
//                     font-family: 600;
//                 }

//                 .inner_join {
//                     width: 85%;
//                     display: flex;
//                     justify-content: start;
//                     align-items: center;

//                     >img {
//                         height: 18px;
//                         width: 18px;
//                     }

//                     >p {
//                         color: #333333;
//                         font-size: 14px;
//                         font-weight: 400;
//                         padding-left: 4px;
//                     }
//                 }
//             }

//             >p {
//                 font-weight: 500;
//                 font-size: 14px;
//                 color: #979C9E;

//                 @media screen and (max-width:756px) {
//                     font-size: 13px;

//                 }
//             }

//             .join_days1 {
//                 padding: 3px;

//                 display: flex;
//                 justify-content: space-between;
//                 align-items: center;
//                 height: 20px;
//                 margin-top: 7px;
//                 font-weight: 500;
//                 font-size: 16px;
//                 font: inherit;

//                 >p {
//                     color: #110C1D;
//                     font-size: 21px;

//                 }

//             }

//         }
//     }
// }

//video

// .student-page {
//     padding: 3px;

//     .search_input_student {
//         padding: 10px 0px;
//         display: flex;

//         .search_input_ {
//             border: 1px solid #DCDCDC !important;
//             width: 20.5% !important;
//             border-radius: 6px !important;
//             height: 35px !important;

//             @media screen and (max-width:900px) {
//                 width: 28.5% !important;

//             }

//             @media screen and (max-width:700px) {
//                 width: 38% !important;
//             }

//             @media screen and (max-width:558px) {
//                 width: 43% !important;
//             }

//             @media screen and (max-width:434px) {
//                 width: 63% !important;
//             }
//         }

//         .filter {
//             background-color: #E0EFEC;
//             height: 35px;
//             border-radius: 8px;
//             margin-left: 14px;
//             border-style: dotted;
//             border-color: #E0EFEC;
//             width: 9%;
//             display: flex;
//             border: 1px dotted #20A58A;
//             justify-content: center;
//             align-items: center;
//             font-size: 14px;
//             color: #20A58A;
//             font-weight: 500;

//             @media screen and (max-width:790px) {
//                 width: 12%;
//             }

//             @media screen and (max-width:558px) {
//                 width: 15%;
//             }

//             @media screen and (max-width:434px) {
//                 width: 18% !important;
//             }
//         }
//     }

//     .main_student {
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: space-between;

//         @media screen and (max-width:550px) {
//             justify-content: space-around;
//         }

//         .main_video {
//             width: 22.5%;
//             margin-top: 10px;

//             border-radius: 8px;
//             height: 250px;
//             background-color: #F9F9F9;

//             @media screen and (max-width:772px) {
//                 width: 29%;

//             }

//             @media screen and (max-width:635px) {
//                 width: 32%;

//             }

//             @media screen and (max-width:535px) {
//                 width: 38%;

//             }

//             @media screen and (max-width:468px) {
//                 width: 57%;

//             }

//             @media screen and (max-width:392px) {
//                 width: 67%;

//             }

//             >h3 {
//                 text-align: center;
//                 font-size: 16px;
//                 font-family: inherit;
//                 font-weight: 500;
//                 color: #484848;
//             }

//             >p {
//                 color: #84818A;
//                 font-size: 14px;
//                 font-family: inherit;
//                 padding: 0px 20px;
//                 font-style: Regular;

//             }

//             .img_student_video {
//                 width: 100%;
//                 display: flex;
//                 justify-content: center;

//                 >img {
//                     width: 90%;
//                     height:
//                         210.89px;
//                     margin-top: 3px;
//                     border-radius: 8px;
//                 }
//             }
//         }
//     }
// }

// .header-title {
//     font-family: Inter;
//     // font-style: SE;
//     font-size: 24px !important;
//     line-height: 32px;
//     letter-spacing: 0.15px;
//     // line-height: 110%;
//     // align: Left;
//     vertical-align: top;
//     letter-spacing: 0.15px;
//     color: rgba(76, 78, 100, 0.87);
//     font-weight: 600 !important;
//     // fill-Color-Style: 'Light/Text/Primary',
//     // fill: 'Solid rgba(76, 78, 100, 0.87)'
// }
// .header-text{
//     font-family: Inter;
//     font-size: 14px !important;
//     line-height: 20px;
//     letter-spacing: 0.15px;
//     vertical-align: top;
//     letter-spacing: 0.15px;
//     color: rgba(76, 78, 100, 0.87) ;
//     font-weight: 400 !important;
// }

// .header-text{
//     font-family: Inter;
//     font-size: 14px !important;
//     line-height: 20px;
//     letter-spacing: 0.15px;
//     vertical-align: top;
//     letter-spacing: 0.15px;
//     color: rgba(76, 78, 100, 0.87) ;
//     font-weight: 400 !important;
// }

// .bottom-text{
//     font-family: Inter;
//     font-size: 16px !important;
//     line-height: 24px;
//     letter-spacing: 0.15px;
//     vertical-align: top;
//     letter-spacing: 0.15px;
//     color: rgba(76, 78, 100, 0.87) ;
//     font-weight: 400 !important;
// }

// .dashboard-title {
//     font-family: Inter;
//     // font-style: SE;
//     font-size: 20px !important;
//     line-height: 24px;
//     letter-spacing: 0.15px;
//     // line-height: 110%;
//     // align: Left;
//     vertical-align: top;
//     letter-spacing: 0.15px;
//     color: rgba(76, 78, 100, 0.87);
//     font-weight: 700 !important;
//     // fill-Color-Style: 'Light/Text/Primary',
//     // fill: 'Solid rgba(76, 78, 100, 0.87)'
// }

// .layout-wrapper{
//     background-color: #F9F9F9;
// }

// // .optionContainer .highlight
// .optionContainer li:hover
// {
//   background: #C73E9D !important;
//   color: #fff;
// }

// .optionContainer .highlight
// {
//   background: #fff !important;
//   color: rgba(76, 78, 100, 0.87);
// }

// .file-details-block {
//     border: 1px solid #ccc;
//     border-radius: 10px;
//     height: 120px;
//     width: 120px;
//     // justify-content: center;
//     align-items: center;
//     display: flex;

// }

// .file-details-container {
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     flex-wrap: wrap;

//     button {
//         position: absolute;
//         top: -5px;
//         right: 0px;
//         background: #ccc;
//     }
// }

// .file-preview-container {
//     overflow: hidden;
//     padding: 10px;
//     width: 100%;
// }
.layout-wrapper-parent{
  background-color: #dbe3e1;
}
li.slide.selected {
  border: none !important;
  padding: 0 !important;
}
p.carousel-status{
  display: none !important;
    padding: 0 !important;
}
.innerboxShadow{
  box-shadow: 0.2px 0.2px 11px 3px #4CBFA7 inset;
}
#rfs-btn{
  height: auto !important;
}
